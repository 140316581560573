module.exports = {
  siteUrl: "https://chargespot.gr",
  siteLang: "el-gr",
  siteCurrency: "EUR",
  title: "Chargespot powered by Protergia",
  shortTitle: "Chargespot",
  description: "Το Chargespot είναι η δική μας ολοκληρωμένη λύση για το μέλλον της ηλεκτροκίνησης. Γίνε σήμερα μέλος του μεγαλύτερου δικτύου ηλεκτροκίνησης.",
  logoUrl: "https://chargespot.gr/images/chargespot-logo.png",

  // Values used in manifest
  siteBackgroundColor: "#31363c",
  siteThemeColor: "#f15a22",
  siteIcon: require.resolve("../src/images/favicon.png"),

  // Image Details used as fallback at metadata
  defaultImage: "https://chargespot.gr/images/chargespot-logo.png",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "Chargespot",

  // Public Urls
  functionsBase: "",
};
