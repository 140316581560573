/* Gatsby Browser API
 * -------------------
 * The file gatsby-browser.js lets you respond to Gatsby-specific events within
 * the browser, and wrap your page components in additional global components.
 * The Gatsby Browser API gives you many options for interacting with the
 * client-side of Gatsby.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

import repositoryConfigs from "./config/prismic-previews-provider-config";

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {element}
    </PrismicPreviewProvider>
  );
};
