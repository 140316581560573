/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 * @returns URL for the provided Prismic document.
 */

const defaultLanguage = require("../../config/site-config").siteLang;

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang.split("-")[0]}/`;
    }
    case "charge_points": {
      return doc.lang === defaultLanguage
        ? `/simeia-fortisis/`
        : `/${doc.lang.split("-")[0]}/chargespots/`;
    }
    case "services": {
      return doc.lang === defaultLanguage
        ? `/ypiresies/`
        : `/${doc.lang.split("-")[0]}/services/`;
    }
    case "service_page": {
      return doc.lang === defaultLanguage
        ? `/ypiresies/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/services/${doc.uid}/`;
    }
    case "chargespot_business_public": {
      return doc.lang === defaultLanguage
        ? `/ypiresies/chargespot-business/`
        : `/${doc.lang.split("-")[0]}/services/chargespot-business/`;
    }
    case "chargespot_business_exclusive": {
      return doc.lang === defaultLanguage
        ? `/ypiresies/chargespot-on-the-go/`
        : `/${doc.lang.split("-")[0]}/services/chargespot-on-the-go/`;
    }
    case "chargespot_home": {
      return doc.lang === defaultLanguage
        ? `/ypiresies/chargespot-home/`
        : `/${doc.lang.split("-")[0]}/services/chargespot-home/`;
    }
    case "chargespot_app": {
      return doc.lang === defaultLanguage
        ? `/efarmogi/`
        : `/${doc.lang.split("-")[0]}/application/`;
    }
    case "support": {
      return doc.lang === defaultLanguage
        ? `/exupiretisi/`
        : `/${doc.lang.split("-")[0]}/customer-support/`;
    }
    case "steps": {
      return doc.lang === defaultLanguage
        ? `/vimata-fortisis/`
        : `/${doc.lang.split("-")[0]}/charging-steps/`;
    }
    case "diagonismos_smart_fortwo": {
      return doc.lang === defaultLanguage
        ? `/diagonismos/`
        : `/${doc.lang.split("-")[0]}/diagonismos/`;
    }
    case "post": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "news": {
      return doc.lang === defaultLanguage
        ? `/nea/`
        : `/${doc.lang.split("-")[0]}/news/`;
    }
    case "simple_page": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "contac": {
      return doc.lang === defaultLanguage
        ? `/epikoinonia/`
        : `/${doc.lang.split("-")[0]}/contact/`;
    }
    case "product": {
      return doc.lang === defaultLanguage
        ? `/product/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/product/${doc.uid}/`;
    }
    default:
      return "/";
  }
};
