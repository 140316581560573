/**
 * @package gatsby-plugin-prismic-previews
 * @version v5
 *
 * @name repositoryConfigs
 * This repositoryConfigs option is used by withPrismicPreview(),
 * withPrismicUnpublishedPreview(), and withPrismicPreviewResolver(). Each of
 * those functions can be provided with a repositoryConfigs object as well, but
 * passing it to the provider only once is easier to maintain.
 *
 * @type {object (required)}
 * @return {array}
 */

import { linkResolver } from "../src/utils/prismic-linkResolver";

const repositoryConfigs = [
  {
    /**
     * @param {string} repositoryConfigs[n].repositoryName
     * @type {string (required)}
     *
     * The name of your Prismic repository. If your Prismic URL is
     * 'https://my-cool-website.prismic.io/api/v2', your repo name is
     * 'my-cool-website'.
     */

    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,

    // --------------------------------------------------------------------------

    /**
     * @param {function} repositoryConfigs[n].linkResolver
     * @type {function (required)}
     *
     * The Link Resolver should be the same Link Resolver provided to
     * gatsby-source-prismic in your app's gatsby-config.js. The return value
     * of your Link Resolver determines the page to which editors will be
     * directed.
     */

    linkResolver,

    // --------------------------------------------------------------------------

    /**
     * @param {function} repositoryConfigs[n].htmlSerializer
     * @type {function}
     *
     * The same HTML Serializer provided to gatsby-source-prismic in your app's
     * gatsby-config.js.
     */

    // htmlSerializer,

    // --------------------------------------------------------------------------

    /**
     * @param {function} repositoryConfigs[n].transformFieldName
     * @type {function}
     *
     * The optional field name transformer for the configured Prismic
     * repository. This should be the same transformFieldName function provided
     * to gatsby-source-prismic in your app's gatsby-config.js if used.
     *
     * Most projects will not need to provide a value for this option.
     */

    // transformFieldName: (fieldName) => fieldName.replace(/-/g, '_'),

    // --------------------------------------------------------------------------

    /**
     * @param {function} repositoryConfigs[n].componentResolver
     * @type {function (required)}
     *
     * A function that determines the component to render during an unpublished
     * preview. This function will be provided a list of nodes that match the URL
     * of the page. Using the list of nodes, the appropriate page template
     * component should be returned
     *
     * The components returned from this function must be wrapped in
     * withPrismicPreview() in order for the component to properly resolve the
     * preview.
     *
     * In most cases, the componentResolverFromMap() helper function can be used
     * as a way to map a Prismic document type to a component.
     *
     * {@link https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#componentresolverfrommap}
     */

    // componentResolver: componentResolverFromMap({
    //   page: PageTemplate,
    // }),

    // --------------------------------------------------------------------------

    /**
     * @param {function} repositoryConfigs[n].dataResolver
     * @type {function}
     *
     * A function that determines the data provided to the resolved component's
     * data prop. This function will be provided a list of nodes that match the
     * URL of the page and the wrapped component's original data prop. Using the
     * list of nodes and the original data, an object of data should be returned
     * that matches the shape of the resolved page template's GraphQL query.
     *
     * In most cases, this can be left empty. The default dataResolver will
     * automatically retrieve the first matching node and add it to the existing
     * data prop using the node's typename.
     */

    // dataResolver: () => {},
  },
];

export default repositoryConfigs;
