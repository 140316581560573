module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-30527845-13"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"656947874903921"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"chargespot","accessToken":"MC5ZYklQTHhFQUFDQUFCRmZB.WO-_vVPvv73vv71Qd1Dvv712Zh3vv70Ieu-_ve-_vTzvv73vv73vv73vv71XIWQv77-977-9S--_vXl5","promptForAccessToken":true,"apiEndpoint":"https://chargespot.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"Chargespot powered by Protergia","short_name":"Chargespot","description":"Το Chargespot είναι η δική μας ολοκληρωμένη λύση για το μέλλον της ηλεκτροκίνησης. Γίνε σήμερα μέλος του μεγαλύτερου δικτύου ηλεκτροκίνησης.","background_color":"#31363c","theme_color":"#f15a22","display":"minimal-ui","icon":"/github/workspace/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d48228822bdd6d3695d1a034d34b293"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
